import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import AuthService from "services/AuthService";
import { fetchOrders } from "./orderSlice";
import { jwtDecode } from "jwt-decode";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN)
    ? localStorage.getItem(AUTH_TOKEN)
    : null,
};

export const signIn = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthService.login(data);
      if (response.status === 200) {
        const token = response.access_token;
        const decodedToken = jwtDecode(response.access_token);

        if (decodedToken.hasOwnProperty("customer_party_ids"))
          localStorage.setItem(AUTH_TOKEN, token);

        return token;
      } else {
        const { identifier } = response.errorType;
        return rejectWithValue(`${response.description} - ${identifier}`);
      }
    } catch (err) {
      console.log("Auth Slice Error:", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/register",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const response = await AuthService.register({ email, password });
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signOut = createAsyncThunk(
  "auth/logout",
  async (data, { rejectWithValue }) => {
    try {
      if (data !== undefined) {
        await AuthService.logout(data);
        localStorage.removeItem(AUTH_TOKEN);
      }
    } catch (err) {
      localStorage.removeItem(AUTH_TOKEN);
      rejectWithValue("Logout Error");
    }
    return null;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
      state.message = "";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
      state.message = "";
      state.showMessage = false;
    },
    setAuthMessage: (state, action) => {
      state.loading = false;
      state.message = action.payload;
      state.showMessage = true;
    },
    // resetApp: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        const decoded = jwtDecode(action.payload);
        if (decoded.hasOwnProperty("customer_party_ids")) {
          state.redirect = "/";
          state.token = action.payload;
        } else {
          state.message = "Something went wrong. Please try again later.";
          state.showMessage = true;
        }
        state.loading = false;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        //state.redirect = "/CustomerPortal";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        //state.redirect = "/";
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        const { error } = action.payload;
        if (error === "Token has been revoked.") {
          state.loading = false;
          state.token = null;
          state.redirect = "/";
        }
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
  setAuthMessage,
} = authSlice.actions;

export default authSlice.reducer;
